document.addEventListener('turbolinks:load', function () {
  const menuButton = document.getElementById('menu-button');
  const closeButton = document.getElementById('close-menu-button');
  const mobileMenu = document.getElementById('mobile-menu');
  const blurredOverlay = document.getElementById('blurred-overlay');

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    const isMenuOpen = !mobileMenu.classList.contains('translate-x-full');
    if (isMenuOpen) {
      mobileMenu.classList.add('translate-x-full');
      blurredOverlay.classList.add('hidden');
    } else {
      mobileMenu.classList.remove('translate-x-full');
      blurredOverlay.classList.remove('hidden');
    }
  };

  // Handle menu button click
  if (menuButton) menuButton.addEventListener('click', toggleMenu);

  // Close the mobile menu when clicking the close button
  if (closeButton) closeButton.addEventListener('click', toggleMenu);

  // Close the menu when clicking on the blurred overlay
  if (blurredOverlay) blurredOverlay.addEventListener('click', toggleMenu);

  // Add click event listeners to all "Make a Bid" buttons and menu links
  document.addEventListener('click', (event) => {
    const clickedElement = event.target.closest('a');
    if (!clickedElement) return;

    // Check if the clicked element is a menu link or "Make a Bid" button
    const isMakeABidButton = clickedElement.textContent.trim() === 'Make a Bid';
    const isMenuLink = clickedElement.closest('#mobile-menu');

    if (isMakeABidButton || isMenuLink) {
      const href = clickedElement.getAttribute('href');

      // Prevent default behavior for "#" or empty hrefs
      if (href === '#' || href === '' || href === null) {
        event.preventDefault();
      }

      // Only toggle the menu if it's currently open
      if (!mobileMenu.classList.contains('translate-x-full')) {
        toggleMenu();
      }
    }
  });
});
